input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control::placeholder{
    color: rgb(134, 132, 132);
 
  }
input[type="number"] {
  -moz-appearance: textfield;
}

  .nav-item{
    cursor: pointer;
  }

  


  
body {
    background: #F0F8FF;
}
.deletebtn{
    display: flex;
    justify-content: end;
}
.before_press{
    margin-bottom: 100px;
  }
  .css-1u9des2-indicatorSeparator{
    display: none;
  }
.Header_text{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.green-icon{
    color: #34C153;
    font-weight: bolder;
    background-color: white;
  border-radius: 50%;
}
.main_header_section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    height: 120px;
}

.header_logo {
    width: 125px;
    height: 61.966px;
}

.onboarding {
    color: #263238;
    /* font-family: Poppins; */
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-left: 1px solid #263238;
    padding-left: 5px;
}

.Navbar_section{
    display: flex;
    width: 1059px;
    margin: auto;
    margin-top: 50px;
    height: 56px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
background: #FFF;
}
.activateding {
    display: flex;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 12px;
background: #30577E;
color: white;
}
.activateding a {
    color: white;
  }

  .nav-link:focus{
    color: white;
  }
.activateding:hover a{
    color: white;
}
.start-hero-section input:focus{
    box-shadow: none;
    border: none;
}
.nav-link{
    color: #30577E;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
width: 201px;
padding: 13px 64px;
justify-content: center;
align-items: center;
gap: 10px;
}
.Driver_container {
    width: 1059px;
    margin: auto;
    min-height: 712px;
    max-height: auto;
    flex-shrink: 0;
    border-radius: 17px;
    background: #FFF;
    margin-top: 50px;
    padding: 50px;
    position: relative;
  }
  
.Device_information{
    color: #263238;
/* font-family: Poppins; */
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.comontext{
height: 94px;
flex-shrink: 0;
color: rgba(38, 50, 56, 0.79);
/* font-family: Poppins; */
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 193.75% */
letter-spacing: 0.32px;
}
.Driver_button{
    width: 204px;
height: 50px;
flex-shrink: 0;
border-radius: 6px;
background: #2A4764;

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
color: #FFF;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.plus_svg{
    filter: invert(1);
}
.continous_button{
    width: 182px;
height: 50px;
flex-shrink: 0;
border-radius: 6px;
background: #2A4764;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
color: #FFF;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: auto;
}
.back_button{
    width: 182px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 6px;
    background:inherit;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
    color: black;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.btn_position {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    margin-top: 10px;
    padding: 50px;
}

.continous_button:nth-child(2) {
    margin-left: auto; 
  }
  .newdriver{
    margin-top: 30px;
    align-items: center;
  }
  .newdriver input:focus{
box-shadow: none;
border-radius: 9px;
border: 1px solid rgba(0, 0, 0, 0.42);

  }
  .dimtext{
    color: rgba(38, 50, 56, 0.79);
  }
  .newdriver input{
    box-shadow: none;
    border-radius: 9px;
    border: 1px solid rgba(0, 0, 0, 0.42);
    height: 51px;
      }
  .question{
    color: #263238;
font-family: Raleway;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .lableforinput{
    color: #263238;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 182.353% */
letter-spacing: 0.34px;
}
.circle_position{
    width: 58px;
height: 58px;
background-color: #2A4764;
border-radius: 50%;
}
.namepart{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 35px;
    padding-left: 5px;
}
.fnam{
    width: 30%;
}
.nmi{
    width: 25%;
}
.lnam{
    width: 30%;
}
.nav1{
    width: 100%;
}
.navbarul1{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
}
.done-main{
margin: auto;
margin-top: 50px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
  .doneheading{
    margin-top: 10px;
    color: #263238;
font-family: Raleway;
font-size: 25px;
font-style: normal;
font-weight: 800;
line-height: normal;
text-align: center;
  }  
.donecontent{
color: rgba(38, 50, 56, 0.79);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.32px;
text-align: center;
  }
@media (max-width: 1024px) {
.Navbar_section{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
}
.activateding{
    width: auto;
}
.Driver_container{
    width: 95%;
    margin: auto;
    margin-top: 50px;
}
.main_table{
    width: 100%;
}
.nav-link{
    width: 100%;
}
  }

@media (max-width: 768px) {
    .Navbar_section{
        display: none;
    }
    .continous_button {
        width: 122px;
        margin-left: auto;
    }
    .Driver_container{
        width: 95%;
    }
   
    .namepart{
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .nmi{
        width: 30%;
    }
    .fnam{
        width: 65%;
    }
    .lnam{
        width: 100%;
    }
    .newdriver{
        align-items: baseline;
    }
  }
@media (max-width: 573px){
    .cdlexp{
        margin-bottom: 30px;
    }
}
@media (max-width: 435px){
    .add_vehicle{
        padding: 20px;
    }
    .inputflds{
        margin-top: 15px;
    }
    .vintxt{
        width: 100%;
    }
    .lableforinput{
        margin-bottom: 10px;
    }
    .comontext{
        height: fit-content;
        text-align: justify;
    }
    .btn_position{
        position: relative;
    bottom: 100%;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    }
    .Driver_container{
        min-height: max-content;
        padding: 20px;
    }
    .namepart{
        flex-wrap: wrap;
        row-gap: 40px;
    }
    .fnam{
        width: 65%;
    }
    .nmi{
        width: 30%;
    }
    .lnam{
        width: 100%;
    }

}