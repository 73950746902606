.css-19bb58m {
    margin: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}
input[type="date"]::placeholder {
    color: rgb(244, 241, 241);
  }
  .form-control::placeholder{
    color: rgb(134, 132, 132);
 
  }