.businesspage{
width: 1059px;
height: fit-content;
border-radius: 17px;
background-color: white;
margin: auto;
margin-top: 40px;
padding: 30px;
}
.business_heading{
color: #263238;
font-family: Raleway;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.dimtester{
    color: rgba(0, 0, 0, 0.44);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 168.75% */
letter-spacing: 0.32px;
}
.interfont{
    font-size: 17px;
    font-family: inter;
}
.customer_email{
    color: #263238;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 182.353% */
letter-spacing: 0.34px;
}
.customer_email span{
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 182.353% */
letter-spacing: 0.34px;
}
.customer_email_input{
    border-radius: 9px;
    height: 51px;
    width: 484px;
    border: 1px solid rgba(0, 0, 0, 0.42);
    padding-left: 10px;
}
.customercoverage{
    margin-top: 30px;
}
.cstmremail{
    margin-top: 20px;
    align-items: center;
}
.business_heading2{
    color: #263238;
    font-family: Raleway;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
   margin-top: 40px;
   margin-bottom: 20px;
    }
    .radio-label {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: 5px;
      }
      .radiobutns{
        display: flex;
        align-items: center;
        column-gap: 50px;
      }
      .radio-input {
        width: 20px;
        height: 20px; 
      }
      .abt-bsns{
        margin-top: 20px;
      }
@media (max-width: 1024px){
    .businesspage{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
  
}
@media (max-width: 768px){
    .forlbl{
        margin-bottom: 10px;
    }
    .formail{
        width: 100%;
    }
    .customer_email_input{
        width: 100%;
    }
}
@media (max-width: 435px){
    .formail{
        width: 100%;
    }
    .businesspage{
        padding: 20px;
    }
    .abt-bsns{
        row-gap: 20px;
        margin-top: 20px;
    }
    .business_heading2{
        margin-top: 20px;
    }
   
}
      