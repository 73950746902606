.radiob{
    display: flex;
    align-items: center;
}
.start-hero-section{
    width: 1059px;
    height: fit-content;
    flex-shrink: 0;
    border-radius: 17px;
    background: #FFF;
    padding: 32px 40px;
    margin: 40px auto;
}
:where(.css-dev-only-do-not-override-6j9yrn).ant-modal .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
    margin-bottom: 0;
    margin-inline-start: 8px;
    background-color: #30577E;
}
.numberrow{
    align-items: baseline;
}

.address-autocomplete {
    position: relative;
    width: 100%;
  }
  
  .form-control-lg {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
  
  .suggestions-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
  }
  
  .suggestions-container::-webkit-scrollbar {
    width: 5px;
  }
  
  .suggestions-container::-webkit-scrollbar-track {
    background: white;
  }
  
  .suggestions-container::-webkit-scrollbar-thumb {
    background: #30577E;
  }
  
  .suggestions-container::-webkit-scrollbar-thumb:hover {
    background: #30577E;
  }
  
  
  .suggestion {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .suggestion:hover {
    background-color: #f0f0f0;
  }
  
.numberrow .col-md-2{
width: 120px;
}
.usdotheading{
    color: #263238;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.usdotcontent{
    margin-bottom: 40px;
    flex-shrink: 0;
    color: rgba(38, 50, 56, 0.79);
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px; /* 182.353% */
    letter-spacing: 0.34px;
}
.Bolanos{
    color: #2A4764;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0.34px;
    text-decoration-line: underline;
}
.radiobtn-label{
color: rgba(38, 50, 56, 0.79);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 33px; /* 206.25% */
letter-spacing: 0.32px;
}
.radiobtns{
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    column-gap: 65px;
    row-gap: 20px;
}

.custom-radio-btn {
    display: none; 
  }
  
  .custom-radio-btn + label {
    position: relative;
    padding-left: 39px; 
    cursor: pointer;
  }
  
  .custom-radio-btn + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px; 
    height: 30px; 
    border: 2px solid rgba(42, 71, 100, 0.64); 
    border-radius: 50%;
    padding: 10px;
    background-color: #fff; 
  }
  
  .custom-radio-btn:checked + label:before {
    background-color:#30577E ; 
    border-color:#30577E ; 
  }
  

  
  
.radiobtn{
    width: 30px;
height: 30px;
flex-shrink: 0;
font-size: 32px;
}
.business-type-section{
    width: 1059px;
height: fit-content;
flex-shrink: 0;
border-radius: 17px;
background: #FFF;
margin: 40px auto;
padding: 32px 40px;
}
.business-type-heading{
    color: #263238;
font-family: Raleway;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
radiob input:focus{
    color: #263238;
    background-color: #263238;
}
.busnes-types{
    color: #2A4764;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 182.353% */
letter-spacing: 0.34px;
text-decoration-line: underline;
width: fit-content;
flex-shrink: 0;
cursor: pointer;
}
.busnes-typess{
    color: #2A4764;
    cursor: pointer;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 182.353% */
letter-spacing: 0.34px;
text-decoration-line: underline;
width: 75px;
flex-shrink: 0;
}

.busnes-types-outer{
    display: flex;
    flex-wrap: wrap;
    column-gap: 75px;
}
.business-type-btn{
    width: 234px;
height: 51px;
flex-shrink: 0;
border-radius: 9px;
border: 1px solid rgba(0, 0, 0, 0.42);
margin: 30px 0px;
}
.busines-type-link{
color: rgba(0, 0, 0, 0.50);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 40%;
display: block;
padding-left: 20px;
}
.business-owner-info{
    color: #263238;
font-family: Raleway;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 40px;
}
.name-part{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 36px auto;
    column-gap: 7px;
}
.name-txt{
    color: #263238;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 182.353% */
letter-spacing: 0.34px;
width: 225px;
margin: 0px;
}
.full-name, .last-name{
    width: 230px;
height: 51px;
flex-shrink: 0;
border-radius: 9px;
border: 1px solid rgba(0, 0, 0, 0.42);
text-align: left;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.sufix{
    width: 160px;
}
.mi{
    width: 110px;
}
.mi, .sufix{
    height: 51px;
    flex-shrink: 0;
    border-radius: 9px;
    border: 1px solid rgba(0, 0, 0, 0.42);
    text-align: left;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.name-fields{
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    margin: auto;
    width: 100%;
}
.full-field{
    width: 100%;
height: 51px;
flex-shrink: 0;
border-radius: 9px;
border: 1px solid rgba(0, 0, 0, 0.42);
text-align: left;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.disclouser-text{
    color: #263238;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
letter-spacing: 0.16px;
margin: 0px;
width: 664px;
}
.disclouser-btn{
    color: #FFF;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 134px;
height: 57px;
flex-shrink: 0;
border-radius: 6px;
background: #2A4764;
}
.disclouser-part{
    width: 95%;
    height: 73px;
    flex-shrink: 0;
    border-radius: 9px;
    border: 1px solid rgba(0, 0, 0, 0.42);
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
}
.last-para{
    width: 95%;
height: 165px;
flex-shrink: 0;
color: #263238;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 164.706% */
letter-spacing: 0.51px;
margin-top: 15px;
text-align: justify;
}
.privacy-policy{
    color: #30577E;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 28px;
letter-spacing: 0.51px;
text-decoration-line: underline;
}
.btns_position{
    margin-left: 0px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
}
.inner-part{
    display: flex;
    column-gap: 20px;
    align-items: center;
}
.small-screen{
    display: none;
}


@media screen and (max-width:1024px) {
    .name-part{
        align-items: baseline;
    }
    .btns_position{
        width: 100%;
    }
    .last-para{
        width: 100%;
    }
.start-hero-section{
    width: 95%;
    margin: 40px auto;
}
.business-type-section{
    width: 95%;
    margin: 20px auto;
}
.disclouser-part {
    width: 100%;
}
.full-name, .last-name{
    width: 55%;
}
.mi, .sufix{
    width: 40%;
}
}

@media screen and (max-width:768px) {
    .css-b62m3t-container {
        position: relative;
        box-sizing: border-box;
        width: 100%;
    }
.name-part {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
}
.inner-part {
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.name-fields {
    display: flex;
    column-gap: 10px;
    margin: auto;
}
.full-name, .last-name {
    width: 200px;
    height: 45px;
}
.mi, .sufix {
    width: 110px;
    height: 45px;
}
.full-field {
    height: 45px;
}

.disclouser-btn{
    height: 50px;
}
.business-type {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
}   
.back_button{
    display: none;
}
.small-screen{
    display: block;
    width: fit-content;
    height: fit-content;
    padding: 15px;
    flex-shrink: 0;
    border-radius: 6px;
    background: inherit;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
    color: black;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.start-hero-section {
    width: 95%;
    margin: 40px auto;
}

.numberrow .col-md-2{
    width: 80px;
    }
}

@media screen and (max-width: 435px) {
    .numberrow{
        column-gap: 30px;
    }
    .numberfields{
        width: 80px !important;
    }
    .business-type-section{
        padding: 20px;
    }
    .radiobtns {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        column-gap: 65px;
        row-gap: 20px;
        flex-direction: column;
    }

    .disclouser-btn{
        width: 100%;
    }
.usdotheading{
    font-size: 16px;
}
.usdotcontent {
    width: auto;
    font-size: 14px;
    line-height: 31px;
    text-align: justify;
    letter-spacing: 0.28px;
}
.start-hero-section{
    margin: 20px auto;
    padding: 20px;
}
.radiobtn-part{
    margin-top: 50px;
}
.radiob{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
} 

.business-type-heading {
    font-size: 18px;
}
.business-type {
    flex-direction: column-reverse;
    width: 100%;
}
.business-type-btn {
    width: 100%;
}
.busnes-types-outer{
    justify-content: space-between;
    column-gap: normal;
}

.business-owner-info {
    color: #263238;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 16px;
}
.name-fields {
    display: flex;
    column-gap: 27px;
    margin: auto;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
}
.full-name, .last-name {
    width: 60%;
    height: 45px;
}
.mi, .sufix {
    width: 35%;
    height: 45px;
}
.inner-part {
    display: flex;
    column-gap: 20px;
    align-items: center;
}
.full-field {
    width: 100%;
    height: 45px;
}
.disclouser-part {
    width: 100%;
    height: 190px;
    flex-direction: column;
    padding: 10px;
}
.disclouser-text{
    width: fit-content;
}

.last-para {
    height: auto;
}






}




