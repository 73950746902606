.container {
    padding: 20px;
  }
  
  .detail-item {
    margin-bottom: 10px;
  }
  
  .detail-item div {
    margin-top: 5px;
  }
  